<template>
  <div class="pt-12 md:pt-32 max-w-8xl mx-auto">
    <div class="md:flex w-full px-6 xl:px-12">
      <div class="md:w-1/2 container">
        <p class="text-3xl md:text-5xl xl:text-7xl fontRg font-bold">
          {{tilte }}</p>
      </div>
       <div class="md:w-1/2 text-center container ">
        <p class="text-xl md:text-3xl fontLt text-justify">
        {{ description }}
        </p>
      </div>
    </div>
    <div class="justify-start px-6 xl:px-12 pt-6 md:mt-16">
      <img :src="image" alt="" />
    </div>
    <div class="lg:flex w-full py-12 ">
      <div class="lg:w-1/2 text-center px-6 lg:px-12">
        <p class="text-xl md:text-3xl fontLt text-justify ">
         {{ Section2description1Left}}
        </p>
      </div>
      <div class="lg:w-1/2 text-center px-6 lg:px-12 pt-6 lg:pt-0">
        <p class="text-xl md:text-3xl fontLt text-justify">
          {{ Section2description2Right }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
   mounted() {
   this.Getdata();
    window.scrollTo(1, 1);
  },
    created() {
      // this.Getdata();
    },

    data() {
    return {
      tilte: '',
      description:'',
      Section2description1Left:'',
      Section2description2Right:'',
      Local:this.$i18n.locale,
      image:'', 
   
    };
  },
  methods: {
    Getdata() {
        this.$http.aboutService.GetAbout(this.Local)
        .then((res) => {
          this.tilte=res.data.tilte,
          this.description=res.data.description
           this.Section2description1Left=res.data.Section2description1Left
          this.Section2description2Right=res.data.Section2description2Right
          this.image=`http://hroof.germanywestcentral.azurecontainer.io:1337${res.data.image[0].url}`
           // this.addSuccessed = res.data.tilte;
  
         })
        .catch((err) => {
          this.addErorr = err;
        });
    },
  },
};
</script>

<style></style>
